var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        class: [
          _vm.dragging ? "bg-gray-200" : "",
          _vm.disabled
            ? "border-none bg-very-light-grey cursor-not-allowed"
            : "",
          _vm.outlineClass,
        ],
        on: {
          dragover: function ($event) {
            $event.preventDefault()
            _vm.dragging = true
          },
          dragleave: function ($event) {
            $event.preventDefault()
            _vm.dragging = false
          },
          drop: function ($event) {
            $event.preventDefault()
            return _vm.inputFile($event, "drop")
          },
        },
      },
      [
        _c("div", { staticClass: "w-full py-4 mx-auto text-center" }, [
          _vm._m(0),
          _c(
            "button",
            {
              staticClass:
                "px-6 py-3 mb-2 mx-auto text-sm font-semibold border-2 border-black disabled:bg-very-light-grey disabled:border-1 disabled:border-gray-400 disabled:cursor-not-allowed",
              attrs: { disabled: _vm.disabled },
              on: {
                click: function ($event) {
                  return _vm.$refs.file.click()
                },
              },
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("components.fl_dnd.select_file")) + " "
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "ml-2 text-xxs font-semibold tracking-widest",
              class: _vm.disabled ? "text-gray-600" : "",
            },
            [
              _c("p", [
                _vm._v(_vm._s(_vm.$t("components.fl_dnd.drag_or_select"))),
              ]),
              _vm.maxFileSize > 0
                ? _c("p", [
                    _vm._v(
                      _vm._s(_vm.$t("components.fl_dnd.max_upload")) +
                        ":" +
                        _vm._s(_vm.formatBytes(_vm.maxFileSize))
                    ),
                  ])
                : _vm._e(),
              _c("p", [
                _vm._v(_vm._s(_vm.$t("components.fl_dnd.file_name_warning"))),
              ]),
              _vm.extensions.length
                ? _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("components.fl_dnd.supported_file_formats")
                        ) +
                        ":" +
                        _vm._s(_vm.extensions.join("/")) +
                        " "
                    ),
                  ])
                : _vm._e(),
            ]
          ),
          _c("input", {
            ref: "file",
            staticClass: "hidden",
            attrs: { type: "file", accept: _vm.extensions.join(",") },
            on: {
              change: function ($event) {
                return _vm.inputFile($event, "select")
              },
            },
          }),
        ]),
      ]
    ),
    _c(
      "p",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.fileError,
            expression: "fileError",
          },
        ],
        staticClass: "text-xs text-left text-red-600 mt-1",
      },
      [_vm._v(_vm._s(_vm.fileError))]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "w-5/12 mb-2 mx-auto flex items-center justify-center" },
      [
        _c("img", {
          staticClass: "h-12 w-12 fill-current text-black",
          attrs: { src: require("@/assets/image/upload.svg") },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }